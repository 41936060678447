import React, { Component } from "react";
import queryString from "query-string";

// app header
import AppHeader from "../../../components/AppHeader/AppHeader";

// seo
import Seo from "../../../components/Seo";

// hook
import { useBookPackQuery } from "../../../hooks/useBookPackQuery";
import { useWindowSize } from "../../../hooks/useWindowSize";

// constant
import constant from "../../../constants";

// utils
import { splitName, splitPlanCodeBookPack } from "../../../utils";

// services
import { sendToCT } from "../../../services/Clevertap";
import { sendToLSQ } from "../../../services/LeadSquared";

// email
import { sendEmailNotifecation } from "../../../services/SendEmail";

// componentts
import WbdtPlanTwinAppForm from "../../../components/BookPack/App/WbdtPlanTwinAppForm";

// api actions
import {
  getClientDetails,
  handlePaymentOfBookPack,
} from "../../../actions/bookPackActions";

class WbdtCarePlanAppPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      serverError: "",
      type: "DIRECT_M2_ANNUAL",
      planCode: "",
      planPrice: 0,
      coupon: "",
      couponApplied: false,
      couponStatus: "",
      clintInfo: {},
      annualPlanPrice: 0,
      selectedPlan: "annualPlan",
      productId: "",
      installmentPlanPrice: 0,
      firstInstallmentPrice: 0,
      quarterlyPlanPrice: 0,
      monthlyPlanPrice: 0,
      toggleButtonValue: "pilot_diabetes",
      medicalDisorderType: "DIABETES",
      annualOfferPrice: 0,
      installmentOfferPrice: 0,
      quarterlyOfferPrice: 0,
      monthlyOfferPrice: 0,
      annualCoupon: "",
      halfEarlyCoupon: "",
      quarterlyCoupon: "",
    };
  }

  // CLIENT DETAILS FUNCTION -> To fetch the client details
  getInitialData = async clientId => {
    try {
      // api call -> to get client details
      const response = await getClientDetails(clientId);
      this.setState({ clintInfo: response.results[0], fetching: true });
    } catch (error) {
      console.error("client api server error", error);
      this.setState({
        serverError: "Unable to fetch your details. Try again",
        fetching: true,
      });
    }
  };

  componentDidMount() {
    const annualPlan = this.props.bookPack.pilot_diabetes.annualPlan[0];
    const installmentPlan =
      this.props.bookPack.pilot_diabetes.installmentPlan[0];
    const quarterlyPlan = this.props.bookPack.pilot_diabetes.quarterlyPlan[0];
    const monthlyPlan = this.props.bookPack.pilot_diabetes.monthlyPlan[0];

    let plan = null;
    if (this.state.selectedPlan === "installmentPlan") {
      plan = installmentPlan;
    } else if (this.state.selectedPlan === "quarterlyPlan") {
      plan = quarterlyPlan;
    } else if (this.state.selectedPlan === "monthlyPlan") {
      plan = monthlyPlan;
    } else {
      plan = annualPlan;
    }

    const annualPlanData = splitPlanCodeBookPack(annualPlan);
    const installmentPlanData = splitPlanCodeBookPack(installmentPlan);
    const quarterlyPlanData = splitPlanCodeBookPack(quarterlyPlan);
    const monthlyPlanData = splitPlanCodeBookPack(monthlyPlan);

    plan &&
      this.setState({
        planCode: annualPlanData[0],
        planPrice: Number(annualPlanData[1]),
        planDay: plan.planDay,
        productId: plan.productId,
        annualPlanPrice: Number(annualPlanData[1]),
        installmentPlanPrice: Number(installmentPlanData[1]),
        firstInstallmentPrice: Number(installmentPlanData[2]),
        quarterlyPlanPrice: Number(quarterlyPlanData[1]),
        monthlyPlanPrice: Number(monthlyPlanData[1]),
        annualOfferPrice: Number(
          this.props.bookPack.pilot_diabetes.annualPlan[0].offerPrice
        ),
        installmentOfferPrice: Number(
          this.props.bookPack.pilot_diabetes.installmentPlan[0].offerPrice
        ),
        quarterlyOfferPrice: Number(
          this.props.bookPack.pilot_diabetes.quarterlyPlan[0].offerPrice
        ),
        monthlyOfferPrice: Number(
          this.props.bookPack.pilot_diabetes.monthlyPlan[0].offerPrice
        ),
      });

    const { clientId, annualCoupon, halfEarlyCoupon, quarterlyCoupon } =
      queryString.parse(this.props.location.search);

    if (clientId) {
      this.getInitialData(clientId);
    }

    // TWIN APP:- Auto applied coupon code for pre-enrol app
    if (annualCoupon) {
      this.setState({
        coupon: annualCoupon,
        annualCoupon: annualCoupon,
        halfEarlyCoupon: halfEarlyCoupon,
        quarterlyCoupon: quarterlyCoupon,
      });
    }
  }

  // PAYMENT FUNCTION -> To handle the payment of book pack
  handlePayNow = async values => {
    const languageArr = [];
    if (values.language && values.language !== "") {
      values.language.map(item => {
        languageArr.push(item.name);
        return languageArr;
      });
    }

    // util function -> to split the name
    const { firstName, lastName } = splitName(values.name);

    // leadSquared params
    let leadSquaredParams = {
      FirstName: firstName,
      LastName: lastName,
      Phone: values.phone,
      EmailAddress: values.email,
      mx_Website_CTA: "Wbdt Pre-Enroll App",
      mx_Latest_Source: "PRE ENROLL APPLICATION",
    };

    let subscriptionType = "";
    if (this.state.selectedPlan === "annualPlan") {
      subscriptionType = "YEARLY";
    } else if (this.state.selectedPlan === "installmentPlan") {
      subscriptionType = "HALF_YEARLY";
    } else if (this.state.selectedPlan === "quarterlyPlan") {
      subscriptionType = "QUARTERLY";
    } else if (this.state.selectedPlan === "monthlyPlan") {
      subscriptionType = "MONTHLY";
    }

    // payment v3
    let paymentBody = {
      firstName: firstName,
      lastName: lastName,
      mobileNumber: values.phone,
      email: values.email,
      planCode: this.state.planCode,
      preferredM2Plan: this.state.type,
      m1PlanCode: this.state.planCode,
      m2PlanCode: this.state.planCode,
      sourceType: "DIGITAL_ORGANIC_TW",
      couponCode: this.state.coupon ? this.state.coupon.toUpperCase() : "",
      languageList: languageArr,
      medicalDisorderType: this.state.medicalDisorderType,
      subscriptionType: subscriptionType,
      address: {
        postalCode: values.pinCode ? values.pinCode : "",
        city: values.city,
        line1: "",
      },
    };

    try {
      this.setState({ loading: true });

      const results = await Promise.allSettled([
        handlePaymentOfBookPack(paymentBody),
        sendToLSQ(leadSquaredParams),
      ]);

      const response = results[0].value;
      const paymentUrl = response.results[0].url;

      this.setState({ loading: false });
      window.location.href = paymentUrl;

      // Clevertap Event
      const { twinApp } = queryString.parse(this.props.location.search);
      if (twinApp && twinApp === "yes") {
        // For Twin App
        if (window.twinApp) {
          const ctData = JSON.stringify({
            action: "cleverTapEvent",
            arguments: {
              event: `Submit Enrollment Click`,
              payload: {
                action: JSON.stringify({
                  msg: "App_Enrol_Page_Cta_Clicked",
                  name: values.name,
                  phone: values.phone,
                  email: values.email,
                  pincode: values.pinCode,
                  coupon: this.state.coupon,
                  paymentPlan: this.state.planCode,
                  firstLanguage: languageArr[0],
                  secondLanguage: languageArr[1]
                }),
              },
            },
          });
          window.twinApp.postMessage(ctData);
        }
      } else {
        sendToCT("Submit Enrollment Click", {
          action: "App_Enrol_Page_Cta_Clicked",
          name: values.name,
          phone: values.phone,
          email: values.email,
          pincode: values.pinCode,
          coupon: this.state.coupon,
          paymentPlan: this.state.planCode,
          firstLanguage: languageArr[0],
          secondLanguage: languageArr[1]
        });
      }
    } catch (error) {
      // send an email
      sendEmailNotifecation(
        window.location.href,
        paymentBody,
        error || {},
        "/marketing/v3/prospect"
      );

      // Clevertap Event
      const { twinApp } = queryString.parse(this.props.location.search);
      if (twinApp && twinApp === "yes") {
        // For Twin App
        if (window.twinApp) {
          const ctData = JSON.stringify({
            action: "cleverTapEvent",
            arguments: {
              event: `Error Resolution Click`,
              payload: {
                action: JSON.stringify({
                  msg: "App_Enrol_Page_Error_Payment",
                }),
              },
            },
          });
          window.twinApp.postMessage(ctData);
        }
      } else {
        sendToCT("Error Resolution Click", {
          action: "App_Enrol_Page_Error_Payment",
        });
      }

      console.error("payment api server error", error);
      this.setState({
        loading: false,
        serverError: constant.BOOKPACK_ERROR_MESSAGES.serverError,
      });
    }
  };

  // COUPON FUNCTION:- Auto applied coupon code on annualPlan based on query parameter in url i.e {twinAppCouponCode}
  handleAutoApplyCoupon = (planPrice, discountAmt) => {
    if (this.state.coupon) {
      this.setState({
        planPrice: Number(planPrice),
        coupon: this.state.coupon,
        couponApplied: true,
      });

      // update plan price of selected plan after coupon applied
      if (this.state.selectedPlan === "installmentPlan") {
        this.setState({
          installmentPlanPrice: Number(planPrice),
          firstInstallmentPrice:
            Number(this.state.firstInstallmentPrice) - Number(discountAmt),
        });
      } else if (this.state.selectedPlan === "quarterlyPlan") {
        this.setState({ quarterlyPlanPrice: Number(planPrice) });
      } else if (this.state.selectedPlan === "monthlyPlan") {
        this.setState({ monthlyPlanPrice: Number(planPrice) });
      } else {
        this.setState({ annualPlanPrice: Number(planPrice) });
      }
    }
  };

  // RADIO CARD FUNCTION -> Handle radio button plan cards
  handlePlan = val => {
    const annualPlanData = splitPlanCodeBookPack(
      this.props.bookPack[this.state.toggleButtonValue].annualPlan[0]
    );
    const installmentData = splitPlanCodeBookPack(
      this.props.bookPack[this.state.toggleButtonValue].installmentPlan[0]
    );

    const quarterlyPlanData = splitPlanCodeBookPack(
      this.props.bookPack[this.state.toggleButtonValue].quarterlyPlan[0]
    );

    const monthlyPlanData = splitPlanCodeBookPack(
      this.props.bookPack[this.state.toggleButtonValue].monthlyPlan[0]
    );

    const selectedPlanData = splitPlanCodeBookPack(
      this.props.bookPack[this.state.toggleButtonValue][val][0]
    );

    const { twinApp } = queryString.parse(this.props.location.search);

    let planCode = "";
    let planPrice = "";

    if (val === "installmentPlan") {
      planCode = installmentData[0];
      planPrice = Number(installmentData[1]);
    } else if (val === "quarterlyPlan") {
      planCode = quarterlyPlanData[0];
      planPrice = Number(quarterlyPlanData[1]);
    } else if (val === "monthlyPlan") {
      planCode = monthlyPlanData[0];
      planPrice = Number(monthlyPlanData[1]);
    } else {
      planCode = annualPlanData[0];
      planPrice = Number(annualPlanData[1]);
    }

    this.setState({
      selectedPlan: val,
      planCode: planCode,
      planPrice: planPrice,
      firstInstallmentPrice: Number(installmentData[2]),
    });

    // when coupon applied and user changes the plan, update plan price of previous plan selected to original plan price
    if (this.state.couponApplied === true && val === "annualPlan") {
      this.setState({
        coupon: this.state.annualCoupon,
        installmentPlanPrice: Number(installmentData[1]),
        firstInstallmentPrice: Number(installmentData[2]),
        quarterlyPlanPrice: Number(quarterlyPlanData[1]),
        monthlyPlanPrice: Number(monthlyPlanData[1]),
        planCode: selectedPlanData[0],
      });

      // Clevertap Event
      if (twinApp && twinApp === "yes") {
        // For Twin App
        if (window.twinApp) {
          const ctData = JSON.stringify({
            action: "cleverTapEvent",
            arguments: {
              event: `Annual Pack Click`,
              payload: {
                action: JSON.stringify({
                  msg: "App_Annual_Plan_Card_Clicked",
                  clientId: this.state.clintInfo.clientId,
                  name: this.state.clintInfo.name,
                  phone: this.state.clintInfo.phone,
                  email: this.state.clintInfo.email,
                  pincode: this.state.clintInfo.postalCode,
                }),
              },
            },
          });
          window.twinApp.postMessage(ctData);
        }
      } else {
        sendToCT("Annual Pack Click", {
          action: "App_Annual_Plan_Card_Clicked",
          clientId: this.state.clintInfo.clientId,
          name: this.state.clintInfo.name,
          phone: this.state.clintInfo.phone,
          email: this.state.clintInfo.email,
          pincode: this.state.clintInfo.postalCode,
        });
      }
    } else if (this.state.couponApplied === true && val === "installmentPlan") {
      this.setState({
        coupon: this.state.halfEarlyCoupon,
        annualPlanPrice: Number(annualPlanData[1]),
        quarterlyPlanPrice: Number(quarterlyPlanData[1]),
        monthlyPlanPrice: Number(monthlyPlanData[1]),
        planCode: selectedPlanData[0],
      });

      // Clevertap Event
      if (twinApp && twinApp === "yes") {
        // For Twin App
        if (window.twinApp) {
          const ctData = JSON.stringify({
            action: "cleverTapEvent",
            arguments: {
              event: `Installments Pack Click`,
              payload: {
                action: JSON.stringify({
                  msg: "App_Installment_Plan_Card_Clicked",
                  clientId: this.state.clintInfo.clientId,
                  name: this.state.clintInfo.name,
                  phone: this.state.clintInfo.phone,
                  email: this.state.clintInfo.email,
                  pincode: this.state.clintInfo.postalCode,
                }),
              },
            },
          });
          window.twinApp.postMessage(ctData);
        }
      } else {
        sendToCT("Installments Pack Click", {
          action: "App_Installment_Plan_Card_Clicked",
          clientId: this.state.clintInfo.clientId,
          name: this.state.clintInfo.name,
          phone: this.state.clintInfo.phone,
          email: this.state.clintInfo.email,
          pincode: this.state.clintInfo.postalCode,
        });
      }
    } else if (this.state.couponApplied === true && val === "quarterlyPlan") {
      this.setState({
        coupon: this.state.quarterlyCoupon,
        annualPlanPrice: Number(annualPlanData[1]),
        installmentPlanPrice: Number(installmentData[1]),
        firstInstallmentPrice: Number(installmentData[2]),
        monthlyPlanPrice: Number(monthlyPlanData[1]),
        planCode: selectedPlanData[0],
      });

      // Clevertap Event
      if (twinApp && twinApp === "yes") {
        // For Twin App
        if (window.twinApp) {
          const ctData = JSON.stringify({
            action: "cleverTapEvent",
            arguments: {
              event: `Quarterly Pack Click`,
              payload: {
                action: JSON.stringify({
                  msg: "App_Quarterly_Plan_Card_Clicked",
                  clientId: this.state.clintInfo.clientId,
                  name: this.state.clintInfo.name,
                  phone: this.state.clintInfo.phone,
                  email: this.state.clintInfo.email,
                  pincode: this.state.clintInfo.postalCode,
                }),
              },
            },
          });
          window.twinApp.postMessage(ctData);
        }
      } else {
        sendToCT("Quarterly Pack Click", {
          action: "App_Quarterly_Plan_Card_Clicked",
          clientId: this.state.clintInfo.clientId,
          name: this.state.clintInfo.name,
          phone: this.state.clintInfo.phone,
          email: this.state.clintInfo.email,
          pincode: this.state.clintInfo.postalCode,
        });
      }
    } else {
      this.setState({
        couponApplied: false,
        coupon: "",
        annualPlanPrice: Number(annualPlanData[1]),
        installmentPlanPrice: Number(installmentData[1]),
        firstInstallmentPrice: Number(installmentData[2]),
        quarterlyPlanPrice: Number(quarterlyPlanData[1]),
        planCode: selectedPlanData[0],
      });
    }
  };

  render() {
    const {
      annualPlanPrice,
      clintInfo,
      loading,
      productId,
      planPrice,
      planDay,
      selectedPlan,
      serverError,
      installmentPlanPrice,
      firstInstallmentPrice,
      toggleButtonValue,
      quarterlyPlanPrice,
      monthlyPlanPrice,
      annualOfferPrice,
      installmentOfferPrice,
      quarterlyOfferPrice,
      monthlyOfferPrice,
    } = this.state;

    const { appHeader, twinApp } = queryString.parse(
      this.props.location.search
    );

    return (
      <div>
        <Seo
          title="Reverse Diabetes, Obesity, PCOS & More"
          description="AI-driven treatment to reverse Diabetes, Hypertension, Obesity & Other Chronic Conditions. 97% reduced blood sugar & meds, 98% eliminated symptoms. Start today."
        />

        {appHeader === "mobile" && twinApp === "yes" && (
          <AppHeader text="" isBack={true} backUrl="mobile_back" />
        )}

        {/* Form */}
        <WbdtPlanTwinAppForm
          appHeader={appHeader}
          twinApp={twinApp}
          price={planPrice}
          handlePayNow={this.handlePayNow}
          loading={loading}
          handleCouponUpdate={this.handleCouponUpdate}
          coupon={this.state.coupon}
          removedCoupon={this.removedCoupon}
          selectedPlan={selectedPlan}
          name={clintInfo.name}
          email={clintInfo.email}
          phone={clintInfo.phone}
          postalCode={clintInfo.postalCode}
          locationSearch={this.props.location.search}
          productId={productId}
          annualPlanPrice={annualPlanPrice}
          planDay={planDay}
          windowSize={this.props.windowSize}
          leadSquaredParam="book_pack_pilot"
          handlePlan={this.handlePlan}
          installmentPlanPrice={installmentPlanPrice}
          firstInstallmentPrice={firstInstallmentPrice}
          toggleButtonValue={toggleButtonValue}
          quarterlyPlanPrice={quarterlyPlanPrice}
          monthlyPlanPrice={monthlyPlanPrice}
          annualOfferPrice={annualOfferPrice}
          installmentOfferPrice={installmentOfferPrice}
          quarterlyOfferPrice={quarterlyOfferPrice}
          monthlyOfferPrice={monthlyOfferPrice}
          couponMessageType="default"
          showAnnualCard={true}
          showInstallmentCard={false}
          showQuarterly={true}
          showMonthly={false}
          showCouponSectionTwinApp={true}
          handleAutoApplyCoupon={this.handleAutoApplyCoupon}
        />
        <div className="text-center text-danger bgLightBlue">
          {serverError} &nbsp;
        </div>
      </div>
    );
  }
}

const WithContext = props => {
  const bookPackPayloadDiabetes = useBookPackQuery("pilot_diabetes");
  const bookPackPayloadObesity = useBookPackQuery("pilot_obesity");
  const bookPackPayloadPreDiabetes = useBookPackQuery("pilot_pre_diabetes");
  const bookPackPayloadMetabolicWellness = useBookPackQuery(
    "pilot_metabolic_wellness"
  );
  const windowSize = useWindowSize();

  const finalBookPackPayload = {
    pilot_diabetes: bookPackPayloadDiabetes,
    pilot_obesity: bookPackPayloadObesity,
    pilot_pre_diabetes: bookPackPayloadPreDiabetes,
    pilot_metabolic_wellness: bookPackPayloadMetabolicWellness,
  };

  return (
    <WbdtCarePlanAppPage
      {...props}
      bookPack={finalBookPackPayload}
      windowSize={windowSize}
    />
  );
};

export default WithContext;
